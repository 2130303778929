import * as LabelPrimitive from "@radix-ui/react-label";
import type { VariantProps } from "cva";
import * as React from "react";

import { cva } from "#app/utils/cva.ts";

const labelVariants = cva({
	base: "w-fit font-medium text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
});

const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
	<LabelPrimitive.Root
		className={labelVariants({
			className,
		})}
		ref={ref}
		{...props}
	/>
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
